.small-text {
    font-family: 'Kanit', sans-serif;
    font-weight: 300;
    font-size: 16px; 
  }

  .small-icon {
    font-family: 'Kanit', sans-serif;
    font-weight: 300;
    font-size: 14.3px; 
  }